<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.33 6.76">
    <polyline
      points="6.24 6.26 8.83 3.38 6.24 .5"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="8.83"
      y1="3.38"
      x2=".5"
      y2="3.38"
      style="fill: none; stroke: currentColor;  stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

